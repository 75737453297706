import * as React from 'react'
import type { HeadFC } from 'gatsby'

// Components
import DefaultHelmet from '../components/atoms/DefaultHelmet'
import PageTemplate from '../components/templates/PageTemplate'
import AllPublishers from '../components/templates/AllPublishers'

const PublishersPage = () => (
  <PageTemplate>
    <AllPublishers />
  </PageTemplate>
)

export default PublishersPage

export const Head: HeadFC = () => (
  <>
    <title>Personas - Comic Colombiano</title>
    <meta
      name="description"
      content="¡Novelas gráficas, revistillas, webcómics y más! Conoce aquí el catálogo más grande de cómic colombiano y sus autores. ¡La inscripción es gratuita!"
    />
    <meta
      name="keywords"
      content="personas,autores,ilustradores,diseñadores,editores,colombiano,comic,base,de,datos,comics,webcomics,webcomic,novela,grafica,arte,revistillas,catalogo"
    />
    <DefaultHelmet />
  </>
)
