import React, { useState } from 'react'

// graphQL
import { getGraphPublishers } from '../../../graphQL/publishers'

// Components
import SimpleCard from '../../atoms/SimpleCard'

// Utils
import { createPath, normalizeText, sortByKeyExist } from '../../../utils/utils'

// Style
import {
  comicsTitle,
  comicsWrapper,
  comicsDescription,
} from './style.module.scss'

const AllPublishers: React.FC = () => {
  const allPublishers = getGraphPublishers()

  const [sorted, setSorted] = useState(
    allPublishers
      .sort((a, b) => (normalizeText(a.name) > normalizeText(b.name) ? 1 : -1))
      .sort(
        (a, b) =>
          sortByKeyExist('image', a, b) || sortByKeyExist('description', a, b)
      )
  )

  return (
    <>
      <h1 className={comicsTitle}>Editoriales</h1>
      <h3 className={comicsDescription}>
        Aquí puedes ver todas la editoriales, colectivos y agrupaciones
        registradas en nuestra página
      </h3>
      <div className={comicsWrapper}>
        {sorted.map((publisher) => (
          <SimpleCard
            link={`/editoriales/${publisher.id}-${createPath(
              publisher.name as string
            )}`}
            title={publisher.name}
            image={
              publisher.image
                ? `https://comiccolombiano.com/.img/publishers/${publisher.image}`
                : undefined
            }
            description={publisher.description ?? undefined}
          />
        ))}
      </div>
    </>
  )
}

export default AllPublishers
